<template>
  <div class="page page-works">
    <div class="header">
      <div class="title">Portfolio</div>
      <div class="sub-title">Projects and Experiments</div>
    </div>


    <div class="page-content">
      <div class="column column2">
        <div class="section">
          <div class="section-title">WaveTrader</div>
          <div class="section-content">
            <div class="card">
              <div class="preview">
                <image-slideshow :images="wavetraderImageList" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column column1">
        <div class="section">
          <div class="section-title">&nbsp;</div>
          <div class="section-content">
            <div class="card">
              <div class="title">WaveTrader</div>
              <div class="subtitle">FastAPI, React, PostgreSQL, REST, Redis, Ray, Tensorflow, APScheduler</div>
              <div class="description">WaveTrader is a trading interface and backend system I developed. It incorporates cutting-edge technologies to provide users with real-time data analysis and machine learning-powered insights. The system utilizes both reinforcement learning for agent trading and rules-based backtesting to optimize trading strategies. Gained experience in data analysis, and machine learning, as well as a deep understanding of the finance industry.
              </div>
              <div class="dates">2023</div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="page-content">
      <div class="column column2">
        <div class="section">
          <div class="section-title">Easy Specs</div>
          <div class="section-content">
            <div class="card">
              <div class="preview">
                <image-slideshow :images="easyspecsImageList" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column column1">
        <div class="section">
          <div class="section-title">&nbsp;</div>
          <div class="section-content">
            <div class="card">
              <div class="title">Easy Specs</div>
              <div class="subtitle">Node, Koa, React, MongoDb, Redis, JsonRpc, GraphQL, Liquid, Heroku</div>
              <div class="description">With Easy Specs, I developed a Shopify app that enables merchants to easily
                display
                product specifications on their online store. The app integrates with multiple APIs and utilizes various
                technologies such as Node.js and React to provide a smooth user experience.</div>
              <div class="dates">2021</div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="page-content">
      <div class="column column2">
        <div class="section">
          <div class="section-title">Wikize</div>
          <div class="section-content">
            <div class="card">
              <div class="preview">
                <image-slideshow :images="wikizeImageList" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column column1">
        <div class="section">
          <div class="section-title">&nbsp;</div>
          <div class="section-content">
            <div class="card">
              <div class="title">Wikize</div>
              <div class="subtitle">Node, Express, React, MongoDb, Redis, WebSockets, Operational Transformation</div>
              <div class="description">I developed a real-time wiki collaboration SaaS application called Wikize. The
                system utilized WebSockets and Operational Transformation to enable multiple users to collaboratively
                edit
                a wiki page in real time. Gained experience in integrating and customizing real-time communication
                technologies.</div>
              <div class="dates">2019</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import ImageSlideshow from "./ImageSlideshow.vue";

export default {
  name: 'PageWorks',
  props: {},
  components: {
    ImageSlideshow
  },
  data() {
    return {
      wavetraderImageList: [
        //"images/wavetrader_1.png",
        "images/wavetrader_2.png",
        //"images/wavetrader_3.png",
      ],
      easyspecsImageList: [
        "images/easyspecs_1.gif",
        "images/easyspecs_2.gif",
        "images/easyspecs_3.gif",
      ],
      wikizeImageList: [
        "images/wikize_1.png",
        "images/wikize_2.png",
        "images/wikize_3.png",
      ],
    }
  }
}
</script>

<style scoped>
.preview {
  height: 314px;
  overflow: hidden;
  width: 100%;
  margin: 1em 0;
  border: 2px solid var(--border-color);
  padding: 5px;
  box-sizing: border-box;
}

.preview img {
  height: 300px;
  width: 100%;
}
</style>
  