<template>
    <div class="theme-toggle">
        <input type="checkbox" class="checkbox" id="checkbox" v-model="isChecked" @change="toggleTheme">
        <label for="checkbox" class="label">
            <font-awesome-icon :icon="['fas', 'sun']" />
            <font-awesome-icon :icon="['fas', 'moon']" />
            <div class="ball"></div>
        </label>
    </div>
</template>
  
  
<script>
import { ref } from "vue";

export default {
    setup() {
        const isChecked = ref(true);

        function toggleTheme() {
            document.body.classList.toggle("dark-theme");
        }

        toggleTheme()

        return {
            isChecked,
            toggleTheme,
        };
    },
};
</script>
  
<style scoped>
* {
    box-sizing: border-box;
}

.theme-toggle {
    position: absolute;
    top: 2em;
    right: 2em;
    cursor: pointer;
}


.checkbox {
    opacity: 0;
    position: absolute;
}

.label {
    cursor: pointer;
    width: 24px;
    height: 50px;
    background-color: var(--border-color);
    display: flex;
    border-radius: 50px;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
    position: relative;
    transform: scale(1.5);
}



.ball {
    width: 20px;
    height: 20px;
    background-color: var(--bg-color);
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 50%;
    transition: transform 0.2s linear;
}

.checkbox:checked+.label .ball {
    transform: translateY(24px);
}
</style>