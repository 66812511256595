<template>
  <div class="page page-lib">
    <div class="header">
      <div class="title">Libraries</div>
      <div class="sub-title">Collection of useful tools</div>
    </div>
    <div class="page-content">

      <div class="column column1">
        <div class="section">
          <div class="section-title">Tools</div>
          <div class="section-content">
            abc
          </div>
        </div>
      </div>
      <div class="column column2">
        <div class="section">
          <div class="section-title">Libraries</div>
          <div class="section-content">
            abc
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'PageLib',
  props: {},
  components: {}
}
</script>

<style scoped></style>
  