<template>
  <div class="page page-resume">
    <div class="header">
      <div class="title">Anthony Esposito</div>
      <div class="sub-title">Full Stack Engineer</div>
    </div>
    <div class="page-content">

      <div class="column column1">
        <div class="section">
          <div class="section-title">About Me</div>
          <div class="section-content">
            <div class="card">
              <div class="description">
                I am an experienced full-stack software engineer with over 20 years of experience building web
                applications.<br /><br />My areas of expertise include front-end and back-end web development, RESTful API
                design, and database management. Lately, my focus and passion have been in the field of machine learning,
                particularly time series forecasting and reinforcement learning.<br /><br />I get excited by leveraging my
                technical skills to develop innovative software solutions that solve real-world problems.
              </div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section-title">Skills</div>
          <div class="section-content">
            <div class="skill-divider">Overview</div>
            <SkillSet skill="Frontend" :level="9" />
            <SkillSet skill="Backend" :level="10" />
            <SkillSet skill="ML" :level="8" />
            <SkillSet skill="Unix System" :level="7" />
            <SkillSet skill="Networking" :level="6" />
            <SkillSet skill="Database" :level="7" />
            <SkillSet skill="UI / UX" :level="8" />
            <div class="skill-divider">Languages</div>
            <SkillSet skill="Python" :level="9" />
            <SkillSet skill="Javascript" :level="10" />
            <SkillSet skill="Ruby" :level="7" />
            <SkillSet skill="Objective-C" :level="4" />
            <SkillSet skill="HTML5" :level="9" />
            <SkillSet skill="CSS3" :level="8" />
            <div class="skill-divider">Frameworks</div>
            <SkillSet skill="Node" :level="8" />
            <SkillSet skill="React" :level="9" />
            <SkillSet skill="Vue" :level="7" />
            <SkillSet skill="FastAPI" :level="9" />
            <SkillSet skill="Ray" :level="8" />
            <SkillSet skill="Tensorflow" :level="6" />
            <div class="skill-divider">Databases</div>
            <SkillSet skill="PostgreSQL" :level="8" />
            <SkillSet skill="MongoDb" :level="9" />
            <SkillSet skill="Redis" :level="8" />
            <SkillSet skill="SQLite" :level="5" />
            <SkillSet skill="MySQL" :level="6" />
            <SkillSet skill="Firebase" :level="4" />
            <div class="skill-divider">Apps</div>
            <SkillSet skill="Photoshop" :level="9" />
            <SkillSet skill="Figma" :level="8" />
            <SkillSet skill="Sketch" :level="7" />
          </div>
        </div>
      </div>
      <div class="column column2">
        <div class="section">
          <div class="section-title">Portfolio</div>
          <div class="section-content">
            <div class="card">
              <div class="title">WaveTrader</div>
              <div class="subtitle">FastAPI, React, PostgreSQL, REST, Redis, Ray, Tensorflow, APScheduler</div>
              <div class="description">WaveTrader is a trading interface and backend system I developed, it incorporates
                cutting-edge technologies to provide users with real-time data analysis and machine learning-powered
                insights. The system utilizes both reinforcement learning for agent trading and rules-based backtesting
                to optimize trading strategies. Gained experience in data analysis, and machine learning, as well as a
                deep understanding of the finance industry.</div>
              <div class="dates">2023</div>
            </div>
            <div class="card">
              <div class="title">Easy Specs</div>
              <div class="subtitle">Node, Koa, React, MongoDb, Redis, JsonRpc, GraphQL, Liquid, Heroku</div>
              <div class="description">With Easy Specs, I developed a Shopify app that enables merchants to easily
                display product specifications on their online store. The app integrates with multiple APIs and utilizes
                various technologies such as Node.js and React to provide a smooth user experience.</div>
              <div class="dates">2021</div>
            </div>
            <div class="card">
              <div class="title">Wikize</div>
              <div class="subtitle">Node, Express, React, MongoDb, Redis, WebSockets, Operational Transformation</div>
              <div class="description">I developed a real-time wiki collaboration SaaS application called Wikize. The
                system utilized WebSockets and Operational Transformation to enable multiple users to collaboratively
                edit a wiki page in real time. Gained experience in integrating and customizing real-time communication
                technologies.</div>
              <div class="dates">2019</div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section-title">Experience</div>
          <div class="section-content">
            <div class="card">
              <div class="title">Chief Technology Officer</div>
              <div class="subtitle">Particle Spectrum Labs</div>
              <div class="description">As the founder and Chief Technology Officer of Particle Spectrum Labs, I led the
                development of a range of SaaS and mobile applications. Through my work at Particle Spectrum Labs, I
                gained valuable experience in managing a technology team, developing software products, and driving
                business growth.</div>
              <div class="dates">2023</div>
            </div>
            <div class="card">
              <div class="title">Full-Stack Developer</div>
              <div class="subtitle">Amerix Global</div>
              <div class="description">As a Full-Stack Developer at Amerix Global, I was responsible for developing
                Shopify apps to enhance merchant store functionality. This role required expertise in front-end and
                back-end web development, as well as knowledge of the Shopify ecosystem.</div>
              <div class="dates">2020</div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section-title">Education</div>
          <div class="section-content">
            <div class="card">
              <div class="title">Bachelor Computer Science</div>
              <div class="subtitle">Howard R. Hughes College of Engineering, University of Las Vegas</div>
              <div class="description">At the Howard R. Hughes College of Engineering, University of Las Vegas, I
                completed a Bachelor's degree in Computer Science with a focus on programming and algorithms.</div>
              <div class="dates">2008</div>
            </div>
          </div>
        </div>
        <div class="section">
          <div class="section-title">Awards</div>
          <div class="section-content">
            <div class="card">
              <div class="title">Money20/20 Hackathon 2014</div>
              <div class="subtitle">Awarded for Filepub</div>
              <div class="description">In 2014, I was awarded at the Money20/20 Hackathon for creating Filepub, an
                innovative Bitcoin embedded JavaScript widget that enabled users to purchase digital downloads on any
                website.</div>
              <div class="dates">2014</div>
            </div>
            <div class="card">
              <div class="title">CES PayPal Hackathon 2015</div>
              <div class="subtitle">Awarded for Modern Sherpa</div>
              <div class="description">In 2015, I was awarded at the CES PayPal Hackathon for Modern Sherpa, a unique
                marketplace app idea that enabled travelers to pair with excursion providers, while integrating new
                mobile Paypal CC processing.</div>
              <div class="dates">2015</div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
  
<script>
import SkillSet from './SkillSet.vue'

export default {
  name: 'PageResume',
  props: {
    msg: String
  },
  components: {
    SkillSet
  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.skill-divider {
  text-transform: uppercase;
  margin-top: 1.4em;
}
</style>
  