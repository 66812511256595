<template>
  <div class="slideshow-container">
    <img v-for="(image, index) in images" :key="index" :src="image"
      :class="['slide', currentImage === index ? 'active' : '', `animation-${animations[index]}`]"
      :style="{ zIndex: currentImage === index ? 2 : 1 }" />
  </div>
</template>
  
<script>
export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      currentImage: 0,
      animations: [],
    };
  },
  mounted() {
    this.setupAnimations();
    setInterval(this.cycleImages, 5000);
  },
  methods: {
    setupAnimations() {
      for (let i = 0; i < this.images.length; i++) {
        //this.animations.push(Math.floor(Math.random() * 4));
        this.animations.push(2);
      }
    },
    cycleImages() {
      this.currentImage = (this.currentImage + 1) % this.images.length;
    },
  },
};
</script>
  
<style scoped>
.slideshow-container {
  width: 720px;
  height: 300px;
  overflow: hidden;
  position: relative;
}

img.slide {
  position: absolute;
  object-fit: cover;
  width: 140%;
  opacity: 0;
  transition: opacity 1s;
}

img.active {
  opacity: 1;
}

img.animation-0 {
  animation: moveRight 20s linear infinite;
}

img.animation-1 {
  animation: moveLeft 20s linear infinite;
}

img.animation-2 {
  animation: moveUp 20s linear infinite;
}

img.animation-3 {
  animation: moveDown 20s linear infinite;
}

@keyframes moveRight {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
}

@keyframes moveLeft {
  0% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes moveUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-50%);
  }
}

@keyframes moveDown {
  0% {
    transform: translateY(-50%);
  }

  100% {
    transform: translateY(0);
  }
}</style>