<template>
  <div class="page page-usr">
    <div class="header">
      <div class="title">Hi.</div>
      <div class="sub-title">The official website of<br />Anthony Esposito</div>
    </div>
    <div class="page-content">

      <div class="column column1">
        <div class="section">

          <div class="section-content">
            <img src="/images/profile.jpg" alt="profile" class="profile-img"/>
            <p>Full-Stack Engineer & Data Scientist</p>
            <p><i>"Carpe Noctem"</i></p>
            <br/>
            <h3>Skills & Technologies:</h3>
            <div class="tags">
              <span class="tag tag-bg">Python</span>
              <span class="tag tag-bg">Tensorflow</span>
              <span class="tag tag-bg">Ray</span>
              <span class="tag tag-bg">PostgreSQL</span>
              <span class="tag tag-bg">JavaScript</span>
              <span class="tag tag-bg">Node.js</span>
              <span class="tag tag-bg">React</span>  
              <span class="tag tag-bg">Vue</span>  
              <span class="tag tag-bg">MongoDB</span>
              <span class="tag tag-bg">GraphQL</span>
              <span class="tag tag-bg">HTML5</span>
              <span class="tag tag-bg">CSS3</span>
              <span class="tag tag-bg">REST</span>
              <span class="tag tag-bg">JsonRPC</span>
              <span class="tag tag-bg">MLFlow</span>
              <span class="tag tag-bg">Redis</span>
              <span class="tag tag-bg">Ruby</span>
              <span class="tag tag-bg">Rails</span>
              <span class="tag tag-bg">Objective-C</span>
              <span class="tag tag-bg">Koa</span>
              <span class="tag tag-bg">Express</span>
              <span class="tag tag-bg">MySQL</span>
              <span class="tag tag-bg">SQLite</span>
              <span class="tag tag-bg">Metabase</span>
              <span class="tag tag-bg">BullMQ</span>
              <span class="tag tag-bg">PM2</span>
              <span class="tag tag-bg">APScheduler</span>
            </div>
          </div>
        </div>
      </div>
      <div class="column column2">
        <div class="section">
          <div class="section-title">About Myself</div>
          <div class="section-content">
            <p>Over the past 20 years, I've had the privilege of diving deep into the world of web and mobile application development. As a Full-Stack Engineer, every project has been a new learning experience.</p>
            <p>Lately, I've been drawn to the complexities of machine learning, especially time series forecasting and reinforcement learning. More than anything, I love the process of exploration, understanding, and creation.</p>
            <p>This site is a testament to my passion and the unique software journeys I've embarked on. Welcome to my slice of the internet.</p>
          </div>
        </div>
        <div class="section">
          <div class="section-title">Highlighted Projects</div>
          <div class="section-content">
            <div class="card">
              <div class="title">WaveTrader</div>
              <div class="subtitle">FastAPI, React, PostgreSQL, REST, Redis, Ray, Tensorflow, APScheduler</div>
              <div class="description">WaveTrader is an innovative trading platform that I developed, employing cutting-edge technologies to offer users real-time data analysis and machine learning-driven insights. The system leverages reinforcement learning for agent trading and rules-based backtesting to optimize trading strategies. It's been a journey into data analysis, machine learning, and a deep understanding of the finance industry.</div>
              <div class="dates">2023</div>
            </div>

            <div class="card">
              <div class="title">Easy Specs</div>
              <div class="subtitle">Node, Koa, React, MongoDb, Redis, JsonRpc, GraphQL, Liquid, Heroku</div>
              <div class="description">Easy Specs is a Shopify app I created to enable merchants to effortlessly display product specifications on their online stores. The app integrates with multiple APIs and utilizes advanced technologies such as Node.js and React to ensure a seamless user experience.</div>
              <div class="dates">2021</div>
            </div>

          </div>
        </div>
        <div class="section">
          <div class="section-title">Let's Connect</div>
          <div class="section-content">
            Interested in collaborating or learning more? Explore my works, library, and resume or get in touch! For direct inquiries, reach out to my email. It's "anthony" [at] this website's domain.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'PageUsr',
  props: {},
  components: {}
}
</script>


<style scoped>

.tag {
    display: inline-block;
    border-radius: 3px;
    padding: .2em .5em .3em;
    border-radius: 2px;
    background: var(--tag-bg);
    color: var(--text-color);
    font-weight: 600;
    margin: .25em .1em;
}

h1.tag {
    margin-left: 0;
    margin-right: 0;
}

.tag-sm {
    font-size: .7em;
    display: inline-block;
    letter-spacing: .15ch;
    font-weight: 400;
}

.tag-lg {
    font-size: 1.2em;
    border-radius: 4px;
}

.tag-bg {
    background-color: var(--border-color);
}

.profile-img {
  width: 100%;
  margin: 1em 0;
  border: 2px solid var(--border-color);
  padding: 5px;
  box-sizing: border-box;
}
</style>
  