// src/router.js
import { createRouter, createWebHistory } from 'vue-router'
import PageUsr from './components/PageUsr.vue'
import PageWorks from './components/PageWorks.vue'
import PageLib from './components/PageLib.vue'
import PageSetup from './components/PageSetup.vue'
import PageResume from './components/PageResume.vue'


const routes = [
  {
    path: '/',
    name: 'usr',
    component: PageUsr
  },
  {
    path: '/works',
    name: 'works',
    component: PageWorks
  },
  {
    path: '/lib',
    name: 'lib',
    component: PageLib
  },
  {
    path: '/setup',
    name: 'setup',
    component: PageSetup
  },
  {
    path: '/resume',
    name: 'resume',
    component: PageResume
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router