<template>
    <div class="footer-bar">
        <div>Custom built using VueJS.</div>
    </div>
</template>
  
  
<script>
export default {
    name: 'FooterBar',
    props: {},
    components: {}
}
</script>
  
<style scoped>
.footer-bar {
    text-align: right;
    font-size: 0.8em;
    margin: 4em 0;
}
</style>
    