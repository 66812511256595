<template>
  <div class="skill-set">
    <div class="skill">{{ skill }}</div>
    <div class="level">
      <span v-for="index in 10" :key="index">
        <span :class="[index <= level ? 'active' : 'inactive', 'circle']"></span>
      </span>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'SkillSet',
  props: {
    skill: String,
    level: String
  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.skill-set {
  display: flex;
  margin: 0.4em;
}

.skill {
  color: var(--text-subtle-color);
  flex-direction: column;
  flex: 2;
  font-weight: bold;
}

.level {
  color: blue;
  flex-direction: column;
  text-align: right;
  flex: 1;
  padding-top: 2px;
  min-width: 140px;
}

.circle {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 5px;
  margin: 2px;
}

.circle.active {
  background-color: var(--text-color);
}

.circle.inactive {
  background-color: var(--border-color);
}
</style>
  