<template>
  <div :class="{ 'dark-theme': isDarkTheme }">
    <ThemeToggle />
    <div class="page-wrap">
      <div class="site-nav">
        <router-link to="/" active-class="selected" exact>/home</router-link>
        <router-link to="/works" active-class="selected" exact>/works</router-link>
        <router-link to="/resume" active-class="selected" exact>/resume</router-link>
      </div>
      <router-view />
      <FooterBar />
    </div>
  </div>
</template>



<script>
import ThemeToggle from './components/ThemeToggle.vue'
import FooterBar from './components/FooterBar.vue'
export default {
  name: 'App',
  components: {
    ThemeToggle,
    FooterBar
  }
}
</script>






<style>
body {
  margin: 0;
  padding: 0;
  color: var(--text-color);
  background-color: var(--bg-color);
  border-color: var(--border-color);
}

#app {
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-size: 15px;
}

a {
  color: var(--link-color);
}

.page-wrap {
  position: relative;
  width: 1080px;
  padding-top: 60px;
  margin: 0 auto;
}

.site-nav {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.9em;
  border-radius: 0 0 5px 5px;
  padding: 1em 0.5em;
}

.site-nav a {
  display: inline-block;
  margin: 0 1em;
  text-decoration: none;
  text-transform: lowercase;
  font-family: monospace;
  font-weight: 500;
  padding-bottom: 0.3em;
  color: var(--text-color);
}

.site-nav a.selected {
  border-bottom: 1px dotted var(--text-color);
  font-weight: 900;
}


.header {
  padding: 0 1.4em;
}

.header .title {
  font-size: 4.4em;
  font-weight: bold;
  text-transform: uppercase;
}

.header .sub-title {
  font-size: 2.4em;
  color: var(--text-subtle-color);
  font-weight: medium;
  text-transform: uppercase;
  margin-top: -0.4em;
}

.section-title {
  font-size: 1.4em;
  text-transform: uppercase;
  font-weight: normal;
  margin-bottom: 0.8em;
  border-bottom: 2px solid var(--border-color);
}

.section {
  margin-bottom: 3em;
}

.column {
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  box-sizing: border-box;
  padding: 0 1.4em;
}

.column1 {
  /*background: red;*/
  max-width: 320px;
}

.column2 {
  /*background: blue;*/
}

.page-content {
  /*background: #F1F1FF;*/
  display: flex;
  margin-top: 3em;
  line-height: 1.4em;
}

.card {
  position: relative;
  margin-bottom: 2em;
}

.card .title {
  font-weight: bold;
  font-size: 1.1em;
}

.card .subtitle {
  font-size: 0.9em;
  text-transform: capitalize;
  margin-bottom: 0.3em;
  color: var(--text-subtle-color);
}

.card .description {

  font-size: 0.9em;
}

.card .dates {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--text-subtle-color);
}



.logo {
  position: absolute;
  top: -60px;
  left: 60px;
}

.logo img {
  height: 200px;
  width: 200px;
}
</style>
