<template>
  <div class="page page-setup">
    <div class="header">
      <div class="title">The Setup</div>
      <div class="sub-title">Collection of useful tools</div>
    </div>
    <div class="page-content">

      <div class="column column1">
        <div class="section">
          <div class="section-title">Goto TechStack</div>
          <div class="section-content">
            <pre>
                <code>
- Server Side
    - Python (lang)
    - FastAPI (api)
    - PostgreSQL (db)
    - Redis (cache)
    - Ray (task)
    - APScheduler (job)

- Client Side
    - Javascript ES6 (lang)
    - Vue.js (framework)
    - 
    - Parcel.js (build)
                </code>
            </pre>
          </div>
        </div>
      </div>
      <div class="column column2">
        <div class="section">
          <div class="section-title">Daily Driver</div>
          <div class="section-content">
            abc
          </div>
        </div>

        <div class="section">
          <div class="section-title">Home Lab</div>
          <div class="section-content">
            abc
          </div>
        </div>

        <div class="section">
          <div class="section-title">Daily Driver</div>
          <div class="section-content">
            abc
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'PageSetup',
  props: {},
  components: {}
}
</script>

<style scoped></style>
  